.css-2ydkcp {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 100%;
    max-width: calc(100% - 32px);
    margin-top: 8px;
}

.css-1o8ti3k {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
}

.css-1j6a75o {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 24px;
    font-weight: 500;
    color: #1E2329;
}

.css-160cmni {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #474D57;
    font-size: 14px;
    margin-top: 8px;
}

.css-1oyssjc {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 24px;
}

.css-1maa655 {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1200px;
    max-width: 100%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.css-63dn76 {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 50%;
    margin-top: 8px;
}

.css-1nvnfl9 {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #1E2329;
    font-size: 14px;
    font-weight: 500;
}

.css-exa5nr {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #474D57;
    font-size: 12px;
    margin-top: 4px;
}

@media screen and (min-width: 768px) {
    .css-1nvnfl9 {
        font-size: 28px;
        font-weight: 600;
    }
}

@media screen and (min-width: 768px) {
    .css-1j6a75o {
        font-size: 40px;
    }
}

@media screen and (min-width: 768px) {
    .css-160cmni {
        font-size: 20px;
        margin-top: 16px;
    }
}

@media screen and (min-width: 768px) {
    .css-exa5nr {
        font-size: 16px;
        margin-top: 8px;
    }
}

body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 20px;
}

.pool_box {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.left-section {
    width: 40%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.left-section h2 {
    font-size: 24px;
    margin-left: 10px;
}

.left-section-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.status {
    left: 150px;
    background-color: #f0f4f7;
    color: #888;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 10px;
}

.details {
    font-size: 16px;
    margin-bottom: 10px;
}

.details span {
    font-weight: bold;
}

.right-section {
    display: flex;
    justify-content: space-between;
    width: 40%;
}

.pool {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f4f7;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
}

.pool .d-flex.align-items-center {
    margin-bottom: 20px;
}

.pool img {
    width: 40px;
    height: 40px;
}

.pool h3 {
    font-size: 18px;
    margin: 10px 0;
}

.pool p {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.pool .stats {
    flex: 1 1;
    font-size: 14px;
    color: #333;
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
}

.pool .stats p {
    display: flex;
    justify-content: space-between;
}

.descript_pool_box {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
    text-align: left;
}


@media screen and (max-width: 768px) {
    .pool_box {
        flex-direction: column;
    }

    .left-section {
        width: 100%;
    }

    .right-section {
        width: 100%;
    }
}
